import { Typography, Paper, Box, SxProps, Theme, Button, Alert, Snackbar } from '@mui/material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { siteToolbarPx } from 'app/layout';
import { Link, Section } from 'components';
import { useEffect, useRef, useState, useCallback } from 'react';
import { data, state } from 'app/types';
import { useGroupedGoals, UserGoal } from 'app/topics/goals/useGroupedGoals';
import { updateTopic, useAppDispatch } from 'app/store/index';
import { CompletedGoalsDialog } from './CompletedGoalsDialog';
import { Timestamp } from 'firebase/firestore';

const GoalCard = (props: {
  sx?: SxProps<Theme>;
  goal: UserGoal;
  userTopics: Record<string, Pick<state.Topic, 'goals'>>;
  markGoalIncomplete: (topicId: string, goalId: string) => void;
  markGoalComplete: (topicId: string, goalId: string) => void;
  onScrollIntoView: (goal: UserGoal) => void;
  onScrollOutOfView: (goal: UserGoal) => void;
}) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const onScrollIntoView = props.onScrollIntoView;
  const onScrollOutOfView = props.onScrollOutOfView;
  const contentRef = useRef<HTMLElement>(null);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showLessButton, setShowLessButton] = useState(false);
  const { goal } = props;

  useEffect(() => {
    const currentRef = targetRef.current;
    if (currentRef) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              onScrollIntoView(goal);
            } else {
              onScrollOutOfView(goal);
            }
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.1,
        },
      );
      observer.observe(currentRef);
      return () => observer.unobserve(currentRef);
    }
  }, [onScrollIntoView, onScrollOutOfView, goal]);

  const isEllipsisActive = (element: HTMLElement) => {
    const tolerance = 2; // In px. Depends on the font you are using
    return element.offsetWidth + tolerance < element.scrollWidth;
  };

  useEffect(() => {
    const content = contentRef.current;
    if (content && isEllipsisActive(content)) {
      setShowMoreButton(true);
    }
  }, []);

  const handleShowMore = () => {
    setShowMoreButton(false);
    setShowLessButton(true);
  };

  const handleShowLess = () => {
    setShowMoreButton(true);
    setShowLessButton(false);
  };

  return (
    <Paper
      ref={targetRef}
      sx={{
        display: 'flex',
        flexWrap: {
          xs: 'wrap',
          md: 'nowrap',
        },
        gap: 5,
        ...props.sx,
      }}>
      <Box sx={{ flexShrink: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
        <Typography fontWeight="bold">{goal.topicLabel}</Typography>
        <Typography sx={{ my: 1 }}>{goal.title}</Typography>
        <Typography
          ref={contentRef}
          sx={{
            lineHeight: '1.1rem',
            height: showLessButton ? 'unset' : '1.1rem',
            whiteSpace: showLessButton ? 'unset' : 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}>
          {goal.content}
        </Typography>
        {showMoreButton ? (
          <Link onClickCapture={handleShowMore} sx={{ '&:hover': { cursor: 'pointer' } }}>
            more
          </Link>
        ) : null}
        {showLessButton ? (
          <Link onClickCapture={handleShowLess} sx={{ mt: 2, '&:hover': { cursor: 'pointer' } }}>
            less
          </Link>
        ) : null}
      </Box>
      <Button
        onClick={() => props.markGoalComplete(props.goal.topicId, props.goal.goalId)}
        // color="darkPrimary"
        sx={{
          alignSelf: 'end',
          whiteSpace: 'nowrap',
          minWidth: 'unset',
          flexGrow: 1,
          width: 'min-content',
          maxWidth: 'min-content',
        }}
        variant="contained">
        Mark as complete
      </Button>
    </Paper>
  );
};

export const GoalsBlock = (props: {
  sx?: SxProps<Theme>;
  topics: data.Topic[];
  userTopics: Record<string, Pick<state.Topic, 'goals'>>;
}) => {
  const { todo, completed } = useGroupedGoals(props.topics, props.userTopics);
  const dispatch = useAppDispatch();
  const [completedGoalsDialogOpen, setCompletedGoalsDialogOpen] = useState(false);
  // When a user just marked a goal as complete, it will be set here, so we can remember
  // which one we need to undo if they choose to click undo
  const [goalToUndo, setGoalToUndo] = useState<undefined | { topicId: string; goalId: string }>(undefined);
  const [visibleGoals, setVisibleGoals] = useState<Set<number>>(new Set());
  const handleScrollIntoView = useCallback(
    (goal: UserGoal) => {
      setVisibleGoals((g) => {
        const result = new Set(g);
        result.add(todo.findIndex((g) => g.goalId === goal.goalId));
        return result;
      });
    },
    [todo],
  );
  const handleScrollOutOfView = useCallback(
    (goal: UserGoal) => {
      setVisibleGoals((g) => {
        const result = new Set(g);
        result.delete(todo.findIndex((g) => g.goalId === goal.goalId));
        return result;
      });
    },
    [todo],
  );

  const markIncomplete = (topicId: string, goalId: string) => {
    const userGoals = props.userTopics[topicId]?.goals;
    const selectedAt = userGoals?.[goalId]?.added;
    if (!selectedAt) {
      return;
    }
    const goals: state.Topic['goals'] = { ...userGoals, [goalId]: { added: selectedAt } };
    if (completed.length === 1) {
      setCompletedGoalsDialogOpen(false);
    }
    dispatch(updateTopic({ topicKey: topicId, data: { goals } }));
  };

  const markCompleted = (topicId: string, goalId: string) => {
    const userGoals = props.userTopics[topicId]?.goals;
    const topicKey = topicId;
    const selectedAt = userGoals?.[goalId]?.added;
    if (!selectedAt) {
      return;
    }
    const goals: state.Topic['goals'] = { ...userGoals, [goalId]: { added: selectedAt, completed: Timestamp.now() } };
    dispatch(updateTopic({ topicKey, data: { goals } }))
      .unwrap()
      .then(() => {
        setGoalToUndo({ topicId, goalId });
      });
  };

  return (
    <Box>
      <Snackbar
        open={Boolean(goalToUndo)}
        autoHideDuration={6000}
        onClose={() => setGoalToUndo(undefined)}
        message="Undo Mark Goal Completed"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        action={
          <Button
            onClick={() => {
              if (goalToUndo) {
                markIncomplete(goalToUndo.topicId, goalToUndo.goalId);
                setGoalToUndo(undefined);
              }
            }}>
            Undo
          </Button>
        }
      />
      <Section title="Your current goals" aria-label="goals" id="goals">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            disabled={completed.length === 0}
            variant="contained"
            disableElevation
            onClick={() => setCompletedGoalsDialogOpen(true)}>
            <Typography sx={{ color: 'inherit', textTransform: 'initial', pr: 2 }}>
              Completed goals {completed.length} / {todo.length + completed.length}
            </Typography>
            <PlaylistAddCheckIcon />
          </Button>
        </Box>
        {todo.length === 0 ? (
          <Alert severity="info">
            <Typography textAlign="center" sx={{ justifySelf: 'center' }}>
              {completed.length === 0 ? (
                <>You haven’t selected any goals yet. You can select goals at the end of each module.</>
              ) : (
                <>
                  You have completed all of your selected goals, well done! You can select more goals at the end of each
                  module.
                </>
              )}
            </Typography>
          </Alert>
        ) : null}
      </Section>
      <CompletedGoalsDialog
        totalGoalCount={completed.length + todo.length}
        open={completedGoalsDialogOpen}
        onClose={() => setCompletedGoalsDialogOpen(false)}
        completed={completed}
      />
      {todo.length > 0 ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: 4,
              overflowX: 'auto',
              justifyContent: 'start',
              alignItems: 'start',
              px: siteToolbarPx,
              pb: 1,
            }}>
            {todo.map((goal, i) => (
              <GoalCard
                onScrollOutOfView={handleScrollOutOfView}
                onScrollIntoView={handleScrollIntoView}
                markGoalIncomplete={markIncomplete}
                markGoalComplete={markCompleted}
                userTopics={props.userTopics}
                goal={goal}
                key={i}
                sx={{
                  maxWidth: 'min(80vw, 40rem)',
                  flexShrink: 0,
                  p: 3,
                }}
              />
            ))}
          </Box>
          <VisibilityIndicator visibleSteps={visibleGoals} steps={todo.length} />
        </Box>
      ) : null}
    </Box>
  );
};

const VisibilityIndicator = (props: { visibleSteps: Set<number>; steps: number }) => {
  return (
    <Box sx={{ display: 'flex', gap: '4px' }}>
      {new Array(props.steps).fill(0).map((_, i) => (
        <Box
          key={i}
          sx={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: props.visibleSteps.has(i) ? 'primary.main' : 'rgba(0, 0, 0, 0.26)',
          }}
        />
      ))}
    </Box>
  );
};
