import { Link } from 'components';
import { GoTo } from 'app/topics/pip';
export const topicTitle = 'Autism: The Fundamentals';
export const goals = [
  {
    id: 'fundamentals-goal1',
    title:
      'Read about one of the autism community organisations included in the Useful Resources Section of the Autism Fundamentals module.',
    content: '',
  },
  {
    id: 'fundamentals-goal2',
    title:
      'Think of one day in the coming week and the tasks you need to do. Identify which tasks are ‘low-energy’ tasks and which are ‘high-energy’ tasks. Make a plan for how you will pair these tasks to help you manage your energy across the day and consider how you will add ‘energy-filling’ activities.',
    content: '',
  },
  {
    id: 'fundamentals-goal3',
    title: 'Make a list of ‘energy-filling’ activities and include at least one each day in the week ahead.',
    content: '',
  },
];
