import { useMemo } from 'react';
import { navigate } from 'gatsby-link';
import { Box, Grid, Button } from '@mui/material';
import { Section } from 'components';
import { SurveyCard } from './SurveyCard';

import type { state, data } from 'app';

export type SurveyOverviewProps = {
  title: string;
  user: state.User;
  surveys: data.Survey[];
  userSurveys: Record<string, state.Survey>;
  completed: boolean;
};

export function SurveyOverview(props: SurveyOverviewProps) {
  const { title, surveys, user, userSurveys, completed } = props;
  const [total, entries] = useSurveyItems(surveys, user, userSurveys);

  const handleContinue = () => {
    const incompleteEntry = entries.find((entry) => (userSurveys[entry.key]?._step ?? 0) < entry.sections.data.length);
    if (incompleteEntry) {
      navigate(`/surveys/${incompleteEntry.key}`);
    }
  };

  return (
    <Section aria-label="user-overview" title={title}>
      <Grid container spacing={4} role="grid">
        {entries.map((entry, index) => {
          return (
            <Grid key={entry.key} item xs={12} sm={6} md={4} lg={3} role="gridcell">
              <SurveyCard
                id={entry.key}
                src={entry.cover?.localFile.publicURL}
                tint={entry.tint}
                title={entry.title}
                extraStyle={entry.extraStyle}
                progress={entry.progress}
                onClick={() => navigate(`/surveys/${entry.key}`)}
                transitionDelay={100 + 200 * index}
              />
            </Grid>
          );
        })}
      </Grid>

      {!completed && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Button variant="contained" color="secondary" onClick={handleContinue}>
            {total > 0 ? 'Continue' : 'Start'}
          </Button>
        </Box>
      )}
    </Section>
  );
}

export type SurveyItem = data.Survey & {
  progress: number | null;
};

const useSurveyItems = (surveys: data.Survey[], user: state.User, userSurveys: Record<string, state.Survey>) => {
  return useMemo(() => {
    const getProgress = (userSurvey?: state.Survey): number | null => {
      if (!userSurvey) return null;
      const { _totalRequired = 0, _completedRequired = 0, _totalOptional = 0, _completedOptional = 0 } = userSurvey;

      if (_totalRequired > 0) {
        return _completedRequired / _totalRequired;
      }

      if (userSurvey._totalOptional > 0) {
        return _completedOptional / _totalOptional;
      }
      return 0;
    };

    // calculate survey section state
    return surveys.reduce<[number, SurveyItem[]]>(
      ([total, items], survey) => {
        const complete = userSurveys[survey.key]?._step === survey.sections.data.length;
        const progress = complete ? 1 : getProgress(userSurveys[survey.key]);
        const item: SurveyItem = { ...survey, progress };
        return [total + (item.progress || 0), [...items, item]];
      },
      [0, []],
    );
  }, [surveys, user, userSurveys]);
};
