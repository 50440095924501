import React from 'react';
import { byCond } from 'app/survey/questions';
import type { Progress, state, data } from 'app';

export function useTopicProgress(topics: Array<data.Topic>, userTopics: Record<string, state.Topic>): Progress {
  const getTopicTotal = (topic: data.Topic) => {
    return topic.contents.reduce<number>((total, content) => {
      if (content.optional) {
        return total;
      } else {
        // visited + activities
        return total + (content.activityKeys ? content.activityKeys.data.length + 1 : 1);
      }
    }, 1);
  };

  return React.useMemo(() => {
    return topics.reduce<Progress>(
      (progress, topic) => {
        const userTopic = userTopics[topic.key];
        return userTopic?.mandatory
          ? {
              total: progress.total + getTopicTotal(topic),
              completed: progress.completed + (userTopic?.progress?.completed || 0),
            }
          : progress;
      },
      {
        total: 0,
        completed: 0,
      },
    );
  }, [topics, userTopics]);
}

export function useSurveyProgress(surveys: Array<data.Survey>, userSurveys: Record<string, state.Survey>): Progress {
  const getSurveyTotal = (survey: data.Survey, userSurvey: state.Survey) => {
    const sections = survey.sections.data;
    return sections.reduce<number>((total, section) => {
      const questions = userSurvey ? section.questions.filter(byCond(userSurvey)) : section.questions;
      return questions.reduce((total, question) => {
        const questionRequired = Boolean(question.required === undefined ? section.required : question.required);
        const required = question.type === 'paragraph' ? false : questionRequired;
        return required ? total + 1 : total;
      }, total);
    }, 0);
  };

  return React.useMemo(() => {
    const progress = surveys.reduce<Progress>(
      (progress, survey) => {
        const userSurvey = userSurveys[survey.key];
        return {
          total: progress.total + getSurveyTotal(survey, userSurvey),
          completed: progress.completed + (userSurvey?._completedRequired || 0),
        };
      },
      {
        total: 0,
        completed: 0,
      },
    );
    return progress;
  }, [surveys, userSurveys]);
}
