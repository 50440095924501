import { Grid } from '@mui/material';
import { Section } from 'components';
import { UserInfo } from './userInfo';
import { Summary } from './summary';
import type { state, data } from 'app';

export type ProfileBlockProps = {
  user: state.User;
  userTopics: Record<string, state.Topic>;
  userSurveys: Record<string, state.Survey>;
  topics: Array<data.Topic>;
  baseline: Array<data.Survey>;
  followup: Array<data.Survey>;
};

export function ProfileBlock(props: ProfileBlockProps) {
  const { user, topics, userTopics, userSurveys, baseline, followup } = props;

  return (
    <Section aria-label="user-overview" title="Progress summary">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
          <UserInfo user={user} topics={topics} userTopics={userTopics} />
        </Grid>
        <Grid item xs={12} md={8} sx={{ display: 'flex' }}>
          <Summary
            topics={topics}
            baseline={baseline}
            followup={followup}
            user={user}
            userTopics={userTopics}
            userSurveys={userSurveys}
          />
        </Grid>
      </Grid>
    </Section>
  );
}
