import { useEffect, useMemo, useState } from 'react';
import { Box, ButtonBase, ButtonBaseProps, Typography } from '@mui/material';
import CheckedIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import { Ring } from 'components/Ring';
import type { data } from 'app';
import { minHeight } from '@mui/system';

export type SurveyCardProps = {
  id: string;
  title: string;
  src: string;
  tint: string;
  extraStyle?: data.Survey['extraStyle'];
  progress: number | null;
  transitionDelay: number;
} & Pick<ButtonBaseProps, 'onClick'>;

export function SurveyCard(props: SurveyCardProps) {
  const { id, progress, transitionDelay, tint, src, title, extraStyle, onClick } = props;
  const enabled = progress !== null;
  const inner = useMemo(() => {
    switch (progress) {
      case 1:
        return <CheckedIcon />;
      case null:
        return <LockIcon />;
      default:
        return `${Math.round(progress * 100)}%`;
    }
  }, [progress]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        paddingTop: '60%',
      }}
      role="figure"
      aria-label={id}>
      <ButtonBase
        sx={{
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          borderRadius: 2,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          color: '#FFF',
          boxShadow: 1,
        }}
        disabled={!enabled}
        aria-label={id}
        aria-disabled={!enabled}
        onClick={onClick}
        focusRipple>
        <Cover src={src} tint={tint} extraStyle={extraStyle} transitionDelay={transitionDelay} enabled={enabled} />
        <Box
          component="section"
          sx={{
            position: 'absolute',
            top: 20,
            left: 60,
            right: 20,
            bottom: 30,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            color: '#FFF',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minHeight: '2.4rem',
              mb: 1.5,
            }}>
            <Typography
              align="right"
              sx={{
                lineHeight: 1.2,
                fontSize: { xs: '1.2rem', md: '1rem', lg: '0.9rem', xl: '1rem' },
              }}>
              {title}
            </Typography>
          </Box>
          <Box>
            <Ring percent={progress || 0} size="small" name={id}>
              {inner}
            </Ring>
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
}

const Cover = (props: {
  src: string;
  tint: string;
  enabled: boolean;
  transitionDelay?: number;
  extraStyle?: data.Survey['extraStyle'];
}) => {
  const { src, enabled, tint, transitionDelay = 0, extraStyle = {} } = props;
  const [slideIn, setSlideIn] = useState(false);
  useEffect(() => {
    setTimeout(() => setSlideIn(true), transitionDelay);
  }, []);

  return (
    <Box
      sx={(theme) => ({
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ...(enabled
          ? {
              opacity: 1,
              bgcolor: tint,
            }
          : {
              opacity: 0.5,
              bgcolor: '#666',
            }),
        '& > img': {
          position: 'absolute',
          display: 'block',
          left: 10,
          bottom: '-20%',
          height: '95%',
          filter: enabled ? 'none' : 'grayscale(100%)',
          transform: 'translateY(100%)',
          opacity: 0,
          transition: theme.transitions.create(['transform', 'opacity'], { duration: 1000 }),
          ...extraStyle,
          ...(slideIn && {
            transform: 'translateY(0)',
            opacity: 1,
          }),
        },
      })}>
      <img src={src} alt="cover" />
    </Box>
  );
};
