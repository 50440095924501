import { useMemo } from 'react';
import moment from 'moment';
import { Box, Button, Typography } from '@mui/material';
import { Welcome } from 'components/Welcome';
import { headings } from 'app';
import { startSurvey, useAppDispatch } from 'app/store';
import { SurveyOverview } from './SurveyOverview';
import cover from './followup_cover.png';

import type { state, data } from 'app';

export type FollowupBlockProps = {
  followup: data.Survey[];
  user: state.User;
  userSurveys: Record<string, state.Survey>;
};

export function FollowupBlock(props: FollowupBlockProps) {
  const { followup, user, userSurveys } = props;
  const dispatch = useAppDispatch();
  const { followupUnlockAt,followupUnlockedAt } = user;

  const timeRemaining = useMemo(() => {
    if (followupUnlockAt) {
      const unlockMoment = moment(followupUnlockAt.toDate());
      return unlockMoment.isAfter(Date.now()) && unlockMoment.fromNow();
    }
  }, [followupUnlockAt]);

  const handleStartSurvey = () => {
    dispatch(startSurvey({ surveyId: followup[0].key, currentStage: 'followup' }));
  };

  return (
    <Box id="followup">
      {user.currentStage !== 'followup' ? (
        <Welcome img={cover} title={headings.followup}>
          <Typography variant="subtitle1" color="textSecondary" align="left" gutterBottom paragraph>
            Many parents find it helpful to reflect on their parenting again after they've completed the program. About
            3-months after your first survey, we'll invite you to complete a follow-up survey. Your responses will also
            help us to improve the program in future, and contribute to important research on parenting and school
            refusal.
          </Typography>
          {followupUnlockAt && (
            <Button variant="contained" color="secondary" onClick={handleStartSurvey} disabled={!followupUnlockedAt}>
              {timeRemaining ? `Unlocks ${timeRemaining}` : 'Start'}
            </Button>
          )}
        </Welcome>
      ) : (
        <SurveyOverview
          title={headings.followup}
          user={user}
          surveys={followup}
          userSurveys={userSurveys}
          completed={Boolean(user.followupCompletedAt)}
        />
      )}
    </Box>
  );
}
