import isEmpty from 'lodash/isEmpty';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  List,
  ListItem,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlined';
import { UserGoal } from './useGroupedGoals';

type CompletedGoalsDialog = Pick<DialogProps, 'open' | 'onClose'> & {
  completed: UserGoal[];
  totalGoalCount: number;
};

export function CompletedGoalsDialog(props: CompletedGoalsDialog) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { open, onClose, completed } = props;

  const hasCompleted = !isEmpty(completed);

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogContent>
        {hasCompleted && (
          <>
            <Box mt={2}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <Typography variant="h6">Completed Goals</Typography>
                <Typography variant="h6">
                  {completed.length} out of {props.totalGoalCount} goal{completed.length === 1 ? '' : 's'} completed
                </Typography>
              </Box>
              <List>
                {completed.map((userGoal) => {
                  return (
                    <ListItem disableGutters key={userGoal.goalId} sx={{ overflow: 'hidden', padding: '2px' }}>
                      <Paper sx={{ padding: '1rem', display: 'flex', alignItems: 'center', width: '100%' }}>
                        <CheckBoxIcon />
                        <Box ml={2} sx={{ overflow: 'hidden' }}>
                          <Typography variant="subtitle1">
                            {userGoal.title}{' '}
                            <Chip
                              label={userGoal.topicLabel}
                              sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                              size="small"
                            />
                          </Typography>
                          <Typography variant="body2" color="textSecondary" gutterBottom>
                            {userGoal.content}
                          </Typography>
                        </Box>
                      </Paper>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </>
        )}
      </DialogContent>
      {onClose && (
        <DialogActions>
          <Button onClick={(e) => onClose(e, 'escapeKeyDown')}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
