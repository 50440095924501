import {
  Stepper,
  Step,
  StepLabel as MuiStepLabel,
  StepIconProps,
  Avatar,
  StepConnector as MuiStepConnector,
  Box,
  Typography,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
  styled,
} from '@mui/material';
import { cyan, blueGrey, pink } from '@mui/material/colors';
import CheckedIcon from '@mui/icons-material/Check';
import { Ring } from 'components';
import { headings } from 'app';
import { useSurveyProgress, useTopicProgress } from './hooks';

import type { Progress, data, state } from 'app';

export type SummaryProps = {
  user: state.User;
  userTopics: Record<string, state.Topic>;
  userSurveys: Record<string, state.Survey>;
  topics: Array<data.Topic>;
  baseline: Array<data.Survey>;
  followup: Array<data.Survey>;
};
export function Summary(props: SummaryProps) {
  const { user, userSurveys, userTopics, baseline, followup, topics } = props;
  const baselineProgress = useSurveyProgress(baseline, userSurveys);
  const followupProgress = useSurveyProgress(followup, userSurveys);
  const topicProgress = useTopicProgress(topics, userTopics);
  const activeStep = user.currentStage && ['survey', 'topic', 'followup'].indexOf(user.currentStage);

  return (
    <Paper
      component="section"
      role="widget"
      sx={{
        py: 2,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Stepper aria-label="steps" activeStep={activeStep} connector={<StepConnector />} alternativeLabel>
        <Step key={0}>
          <StepLabel StepIconComponent={StepIcon}>{headings.survey}</StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel StepIconComponent={StepIcon}>{headings.topic} </StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel StepIconComponent={StepIcon}>{headings.followup} </StepLabel>
        </Step>
      </Stepper>

      <Grid sx={{ mt: 2, flexGrow: 1 }} container spacing={2} role="grid">
        <Grid item xs={4} role="gridcell">
          <RingColumn label="Baseline assessment" progress={baselineProgress} />
        </Grid>
        <Grid item xs={4} role="gridcell">
          <RingColumn label="Parenting program" progress={topicProgress} />
        </Grid>
        <Grid item xs={4} role="gridcell">
          <RingColumn label="Follow-up assessment" progress={followupProgress} />
        </Grid>
      </Grid>
    </Paper>
  );
}

const StepLabel = styled(MuiStepLabel)(({ theme }) => {
  return {
    '& .MuiStepLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.72rem',
      },
    },
  };
});

const StepIcon = (props: StepIconProps) => {
  const { icon, active, completed, classes } = props;
  return (
    <Avatar
      sx={{
        ...(active && { color: 'rgba(255,255,255,.9)', bgcolor: pink[600] }),
        ...(completed && { color: '#FFF', bgcolor: cyan[600] }),
      }}>
      {completed ? <CheckedIcon /> : icon}
    </Avatar>
  );
};

const StepConnector = styled(MuiStepConnector)({
  top: 20,
  left: 'calc(-50% + 30px)',
  right: 'calc(50% + 30px)',
});

const RingColumn = (props: { label: string; progress: Progress }) => {
  const { label, progress } = props;
  const { total, completed } = progress;
  const percent = total === 0 ? 0 : Math.round((100 * completed) / total);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Ring percent={percent / 100} size={matches ? 'medium' : 'large'} trackColor={blueGrey[50]}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body1">{percent}%</Typography>
          <Typography variant="caption" color="textSecondary">
            {completed}/{total}
          </Typography>
        </Box>
      </Ring>

      <Typography
        sx={{
          mt: 2,
          display: { sm: 'none', md: 'block' },
        }}
        variant="subtitle1"
        color="textSecondary">
        {label}
      </Typography>
    </Box>
  );
};
