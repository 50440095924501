import { Box, Button, Typography } from '@mui/material';
import { headings } from 'app';
import { startSurvey, useAppDispatch } from 'app/store';
import { Welcome } from 'components/Welcome';
import { SurveyOverview } from './SurveyOverview';
import cover from './survey_cover.png';

import type { data, state } from 'app';

export type BaselineBlockProps = {
  baseline: data.Survey[];
  user: state.User;
  userSurveys: Record<string, state.Survey>;
};

export function BaselineBlock(props: BaselineBlockProps) {
  const { baseline, user, userSurveys } = props;
  const dispatch = useAppDispatch();

  const handleStartSurvey = () => {
    dispatch(startSurvey({ surveyId: baseline[0].key, currentStage: 'survey' }));
  };

  const stage = user.currentStage ? 'entries' : 'welcome';
  return (
    <Box id="baseline">
      {stage === 'welcome' ? (
        <Welcome img={cover} title="Baseline assessment and feedback">
          <Typography variant="subtitle1" color="textSecondary" align="justify" gutterBottom paragraph>
            Welcome to the PiP Kids-Autism program! The first stage of the program includes an initial online
            assessment, during which you'll receive personalised feedback. After the surveys, you'll have access to your
            tailored program right away. Click the button to get started.
          </Typography>
          <Button variant="contained" color="secondary" onClick={handleStartSurvey}>
            Start
          </Button>
        </Welcome>
      ) : (
        <SurveyOverview
          title={headings.survey}
          user={user}
          surveys={baseline}
          userSurveys={userSurveys}
          completed={Boolean(user.surveyCompletedAt)}
        />
      )}
    </Box>
  );
}
