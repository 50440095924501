import { useState } from 'react';
import { Avatar, Box, Button, ButtonBase, Paper, Popover, Typography } from '@mui/material';
import { cyan, blueGrey } from '@mui/material/colors';
import { avatars } from 'app/layout/avatars';
import { useAppDispatch, updatePhotoURL } from 'app/store';
import { getAvatar, getDisplayName } from 'app/helpers';

import type { state, data } from 'app';
import { navigate } from 'gatsby';

export type UserInfoProps = {
  user: state.User;
  topics: Array<data.Topic>;
  userTopics: Record<string, state.Topic>;
};

export function UserInfo(props: UserInfoProps) {
  const { user } = props;

  const dispatch = useAppDispatch();

  const avatarUrl = getAvatar(user);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleSetAvatar = (url: string) => {
    dispatch(updatePhotoURL(url));
    setAnchorEl(null);
  };
  return (
    <Paper
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        py: 4,
        px: 2,
        bgcolor: cyan[600],
        color: 'rgba(255,255,255,.9)',
      }}>
      <ButtonBase
        sx={{
          mb: 2,
          borderRadius: '50%',
          overflow: 'hidden',
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Avatar
          sx={{
            width: 100,
            height: 100,
            bgcolor: cyan[400],
          }}
          src={avatarUrl}></Avatar>
      </ButtonBase>
      <AvatarPopupover anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onChange={handleSetAvatar} />

      <Typography variant="body2" color="inherit" sx={{ color: 'rgba(255,255,255,.6)' }} gutterBottom>
        {user.email}
      </Typography>

      <Typography variant="subtitle1" color="inherit" gutterBottom>
        {getDisplayName(user)}
      </Typography>
    </Paper>
  );
}

type AvatarPopupoverProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onChange: (url: string) => void;
};

const AvatarPopupover = (props: AvatarPopupoverProps) => {
  const { anchorEl, onClose, onChange } = props;
  return (
    <Popover
      id="avatar-menu"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          p: 2,
          width: '195px',
        }}>
        {[...avatars.female, ...avatars['non-binary'], ...avatars.male].map((url, index) => {
          return (
            <ButtonBase key={index} onClick={() => onChange(url)}>
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                  m: 1,
                  bgcolor: blueGrey[100],
                }}
                variant="rounded"
                src={url}
              />
            </ButtonBase>
          );
        })}
      </Box>
    </Popover>
  );
};
